import { useEffect, useRef, useState } from "react"
import { useReactToPrint } from "react-to-print"
import { CardFront } from "../Components/Card/CardFront"
import { CardBack } from "../Components/Card/CardBack"

export const PrintCardProvider = () => {
    const printRef = useRef()
    const handlePrint = useReactToPrint({
        content: () => printRef.current
    })

    const [isAnverso, setIsAnverso] = useState(false)
    const [Name, setName] = useState(null)
    const [NID, setNID] = useState(null)

    useEffect(() => {
        if (Name && NID) {
            handlePrint()
            setIsAnverso(false)
            setName(null)
            setNID(null)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Name, NID, isAnverso])

    useEffect(() => {
        const print = (event) => {
            setIsAnverso(event.detail.isAnverso)
            setName(event.detail.Name)
            setNID(event.detail.NID)
        }

        window.addEventListener('printCard', print)

        return () => {
            window.removeEventListener('printCard', print)
        }
    })

    return (
        <div style={{ display: 'none' }}>
            <div ref={printRef}>
                {isAnverso ?
                    <CardFront /> :
                    <CardBack NID={NID} Name={Name} />
                }
            </div>
        </div>
    )
}