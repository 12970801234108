import { Button, Dialog, DialogActions, DialogContent, Stack } from "@mui/material"
import { ScanBarcode } from "./ScanBarcode"
import { useNavigate } from "react-router-dom"

export const SearchHermano = ({ open, onClose }) => {
  const navigate = useNavigate()

  const handleScanner = async (cardId) => {
    navigate(`/hermano/${cardId}`)
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth='md'
      fullWidth
    >
      <DialogContent>
        <Stack height='600px' justifyContent='end'>
          <ScanBarcode onComplete={handleScanner} />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={onClose}>Cerrar</Button>
      </DialogActions>
    </Dialog>
  )
}
