import { ArrowDownward, Church, Edit, CreditCard, MoreVert } from "@mui/icons-material"
import { Box, Divider, IconButton, Paper, Popover, Stack, Tooltip, Typography } from "@mui/material"
import { secondaryColor } from "../../theme"
import { formatDate } from "../../utils"
import { useState } from "react"
import { CustomIconButton } from "../CustomIconButton"
import EditHermano from "../EditHermano"
import { PrintCardDialog } from "../Card/PrintCardDialog"
import { AcceptCancelDialog } from "../AcceptCancelDialog"

export const HermanoData = ({ hermano, NID }) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const [openEdit, setOpenEdit] = useState(false)
  const [openPrintCard, setOpenPrintCard] = useState(false)
  const [openBajaDialog, setOpenBajaDialog] = useState(false)
  const [openDeathDialog, setOpenDeathDialog] = useState(false)

  const handleOpenEdit = () => {
    setOpenEdit(true)
    setAnchorEl(null)
  }

  const handlePrintCard = () => {
    setOpenPrintCard(true)
    setAnchorEl(null)
  }

  const closeBaja = () => {
    setOpenBajaDialog(false)
    setAnchorEl(null)
  }

  const closeDeath = () => {
    setOpenDeathDialog(false)
    setAnchorEl(null)
  }

  const editHermano = async (newHermano) => {
    await hermano.edit(newHermano)
  }

  const handleBaja = async () => {
    await hermano.baja(true)
    setAnchorEl(null)
  }

  const handleDeath = async () => {
    await hermano.death()
    setAnchorEl(null)
  }

  return (
    <>
      <Paper>
        <Stack direction='column' p='.75rem' bgcolor={secondaryColor}>
          <Stack direction='row' alignItems='center' justifyContent='space-between'>
            <Stack direction='row' alignItems='end'>
              <Typography variant="h6">{hermano.data.Name} {hermano.data.LastName}</Typography>
              <Typography variant="subtitle1" color='grey' style={{ marginLeft: '.5rem' }}>#{NID}</Typography>
            </Stack>
            <Tooltip title='Más'><IconButton onClick={(event) => setAnchorEl(event.currentTarget)}><MoreVert /></IconButton></Tooltip>
          </Stack>
        </Stack>
        <Divider />
        <Box p='1rem'>
          <Typography variant="body1">F. Nacimiento: {formatDate(hermano.data.BirthDate)}</Typography>
          <Typography variant="body1">Tipo de hermano: {hermano.data.Type}</Typography>
          <Typography variant="body1">DNI: {hermano.data.DNI}</Typography>
          <Typography variant="body1">Email: {hermano.data.Email}</Typography>
          <Typography variant="body1">Teléfono: {hermano.data.Phone}</Typography>
          <Typography variant="body1">Dirección: {hermano.data.Address} {hermano.data.Zip ? `(${hermano.data.Zip})` : ''}</Typography>
          <Typography variant="body1">Ciudad: {hermano.data.City}</Typography>
        </Box>
      </Paper>

      <Popover
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
      >
        <Stack direction='column' gap='.5rem' p='.5rem'>
          <CustomIconButton label='Editar' icon={<Edit fontSize='small' />} onClick={handleOpenEdit} />
          <CustomIconButton label='Imprimir carnet' icon={<CreditCard fontSize="small" />} onClick={handlePrintCard} />
          {!hermano.data.Baja && <CustomIconButton label='Dar de Baja' icon={<ArrowDownward fontSize='small' htmlColor='red' />} onClick={() => setOpenBajaDialog(true)} color='red' />}
          {!hermano.data.Death && <CustomIconButton label='Defunción' icon={<Church fontSize='small' />} onClick={() => setOpenDeathDialog(true)} />}
        </Stack>
      </Popover>

      <EditHermano open={openEdit} closeHandler={() => setOpenEdit(false)} successHandler={editHermano} hermano={hermano.data} />
      <PrintCardDialog open={openPrintCard} onClose={() => setOpenPrintCard(false)} hermano={hermano.data} />
      <AcceptCancelDialog
        open={openBajaDialog}
        onClose={closeBaja}
        title='¿Estás seguro?'
        contentText='Si aceptas el hermano será dado de baja. ESTA ACCIÓN NO SE PUEDE DESHACER'
        onCancel={closeBaja}
        onAccept={handleBaja}
        cancelLabel='Cancelar'
        acceptLabel='Dar de Baja'
      />
      <AcceptCancelDialog
        open={openDeathDialog}
        onClose={closeDeath}
        title='¿Estás seguro?'
        contentText='Si aceptas el hermano será dado como difunto. ESTA ACCIÓN NO SE PUEDE DESHACER'
        onCancel={closeDeath}
        onAccept={handleDeath}
        cancelLabel='Cancelar'
        acceptLabel='Aceptar'
      />
    </>
  )
}