export const usePrint = () => {
  const printCuota = (cuota) => {
    window.dispatchEvent(new CustomEvent('printCuota', { detail: cuota }))
  }

  const printFile = (title, text) => {
    window.dispatchEvent(new CustomEvent('printFile', { detail: { title, text } }))
  }

  const printCard = (isAnverso, NID, Name) => {
    window.dispatchEvent(new CustomEvent('printCard', { detail: { isAnverso, NID, Name } }))
  }

  return {
    printCuota,
    printFile,
    printCard
  }
}