import { Stack } from "@mui/material";

export const CardFront = ({ color, white }) => (
  <Stack style={{ width: '850px', height: '535px' }} justifyContent='center'>
    <img
      src='https://hdad.magicdidac.com/tarjeta_anverso.png'
      width='100%'
      alt="Escudo de la Hermandad"
    />
  </Stack>
)
