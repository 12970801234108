import { Stack, Typography } from "@mui/material"
import Barcode from "react-barcode"

export const CardBack = ({ Name, NID }) => (
  <Stack style={{ width: '850px', height: '535px' }} justifyContent='center' >
    <Stack direction='column' padding='1.6rem' justifyContent='space-between' height='100%' gap='1rem'>
      <Stack gap='1rem'>
        <Stack direction='column' alignItems='center'>
          <Typography variant='h5' fontWeight='700' fontSize='1.7rem'>Carnet de Hermano</Typography>
        </Stack>
        <p style={{ fontSize: '1.21rem', fontWeight: '600', textAlign: 'justify' }}>Esta tarjeta es personal e intransferible. En caso de extravío, comuníquese inmediatamente con la junta de gobierno de la Hermandad (tendrá un coste de 5€). La utilización de esta tarjeta supone la aceptación de las normas establecidas por la Hermandad y la aceptación de la utilización de sus datos facilitados para uso interno de la misma. Esta tarjeta le identifica como hermano de la Hermandad siempre que esté al corriente de pago. Si tiene cualquier consulta, diríjase a la casa Hermandad o bien escriba un correo a hermandadmataro@gmail.com</p>
      </Stack>
      <Stack alignItems='center'>
        <Typography variant='h3' fontWeight='600'>{Name}</Typography>
        <Typography variant='h3' fontWeight='600'>NID: {NID}</Typography>
        <Barcode value={NID || 'nothing'} width={3} />
      </Stack>
    </Stack>
  </Stack>
)