import { Box, Button, Dialog, DialogContent, Stack, Step, StepLabel, Stepper, Typography } from "@mui/material"
import { useState } from "react"
import { usePrint } from "../../Data/Print/custom-hooks"
import { ScanBarcode } from "../ScanBarcode"
import { useNotifications } from "@magicdidac/notifications"
import { TaskAlt } from "@mui/icons-material"

const steps = ['Imprimir Anverso', 'Imprimir Reverso', 'Comprobar carnet', 'Finalizar']

export const PrintCardDialog = ({ hermano, open, onClose }) => {
  const print = usePrint()
  const notifications = useNotifications()

  const [activeStep, setActiveStep] = useState(0)

  const handleClose = () => {
    setActiveStep(0)
    onClose()
  }

  const handlePrint = () => {
    print.printCard(activeStep === 0, hermano.NID, `${hermano.Name} ${hermano.LastName}`)
  }

  const handleNextStep = () => {
    setActiveStep((prev) => prev + 1)
  }

  const handleBack = () => {
    setActiveStep((prev) => prev - 1)
  }

  const hanldeScan = (cardId) => {
    if (cardId === hermano.NID) {
      handleNextStep()
    } else {
      notifications.error('El NID escaneado no es correcto.')
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth='md'
      fullWidth
    >
      <DialogContent>
        <Stepper activeStep={activeStep}>
          {steps.map((label) => {
            const stepProps = {}

            return (
              <Step key={label} {...stepProps}>
                <StepLabel>{label}</StepLabel>
              </Step>
            )
          })}
        </Stepper>
        <Box height='500px'>
          {activeStep === 0 &&
            <Stack alignItems='center' marginTop='2rem'>
              <Typography variant='body1'>Inserte una tarjeta en blanco en la impresora. Después dele a "Imprimir".</Typography>
              <Typography variant='body2'>Nota: En el cuadro de impresión, recuerde seleccionar la impresora de tarjetas y poner la escala a 40.</Typography>
              <img src='https://hdad.magicdidac.com/anim_anverso.gif' width='400px' alt="Animación poner tarjeta" />
              <Button onClick={handlePrint} variant='contained'>Imprimir</Button>
            </Stack>
          }
          {activeStep === 1 &&
            <Stack alignItems='center' marginTop='2rem'>
              <Typography variant='body1'>Recoja la tarjeta y vuelva a insertarla dándole la vuelta. Después dele a "Imprimir".</Typography>
              <Typography variant='body2'>Nota: En el cuadro de impresión, recuerde seleccionar la impresora de tarjetas y poner la escala a 40.</Typography>
              <img src='https://hdad.magicdidac.com/anim_reverso.gif' width='400px' alt="Animación dar vuelta a la tarjeta" />
              <Button onClick={handlePrint} variant='contained'>Imprimir</Button>
            </Stack>
          }
          {activeStep === 2 &&
            <Stack alignItems='center' marginTop='2rem'>
              <Typography variant='body1'>Escanea el código de barras del carnet con el escaner.</Typography>
              <ScanBarcode onComplete={hanldeScan} />
            </Stack>
          }
          {activeStep === 3 &&
            <Stack alignItems='center' justifyContent='center' height='100%' marginTop='2rem' gap='2rem'>
              <TaskAlt fontSize='large' color='success' />
              <Typography variant='h5' color='success'>El carnet ha sido comprobado con éxito</Typography>
            </Stack>
          }
        </Box>
        <Stack direction='row' justifyContent='space-between' width='100%' marginTop='4rem'>
          <Button onClick={handleBack} disabled={activeStep === 0}>Atrás</Button>
          <Button disabled={activeStep === 2} onClick={activeStep === steps.length - 1 ? handleClose : handleNextStep}>{activeStep === steps.length - 1 ? 'Finalizar' : 'Siguiente'}</Button>
        </Stack>
      </DialogContent>
    </Dialog>
  )
}
